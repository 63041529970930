import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from './../../config/theme/baseTheme';

const drawerWidthClose = 90;
const drawerWidthOpen = 320;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 80,
      padding: theme.spacing(2),
      boxShadow: `0 3px 10px 0px ${COLORS.BLACK_SHADOW}`,
      '& .countrySelect': {
        marginRight: theme.spacing(2),
        minWidth: 180,
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
          border: "none",
          borderRight: `1px solid ${COLORS.BLACK_INPUT_BORDER}`
        }
      },
      '& .MuiPaper-rounded':{
        width:160,
        '& ul a':{
          color: "#6F6F6F !important",
          '&:hover':{
            backgroundColor: "#E7EFF6"
          }
        }
      }
      ,
      '& .MuiButton-text':{
        fontWeight: 500,
        padding: "6px 20px !important",
        color: "#6F6F6F !important",
        '&:hover':{
          backgroundColor: "#fff !important"
        }
      },
      '& .selected-menu':{
        '& .MuiButton-label':{
          borderBottom: "2px solid #0E5AA7",
          fontWeight: 700,
          color: "#0E5AA7"
        }
      }
    },
    logoSection: {
      paddingRight: theme.spacing(5),
      '& a': {
        textDecoration: "none"
      },
      '& .CaMSLogo': {
        height: 60,
        width: 260
      },
      '& .MuiIconButton-root':{
        color: "#B2B2B2",
        marginLeft: 8,
        '&:hover':{
          backgroundColor: '#fff'
        }
      }
    },
    tabsSection: {
      marginRight: "auto",
      marginLeft: theme.spacing(3)
    },
    avatarDetails: {
      textAlign: 'right',
      paddingRight: theme.spacing(1.5),
      '& .avatarItem': {
        fontSize: 12,
        fontWeight: 500
      },
      '& .name': {
        fontWeight: 600
      },
      '& .logoutBtn': {
        cursor: "pointer"
      }
    },
    avatarLogo: {
      '& .MuiAvatar-root': {
        width: 35,
        height: 35,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main
      }
    },
    countryMenuPaper: {
      maxHeight: "200px !important",
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: '8px !important',
      boxShadow: "none !important"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidthOpen,
      width: `calc(100% - ${drawerWidthOpen}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidthOpen,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      borderRight: "none",
      '& .MuiDrawer-paperAnchorDockedLeft':{
        borderRight: "none",
        marginTop: 80,
        backgroundColor: "#F5F5F5"
      }
    },
    drawerOpen: {
      width: drawerWidthOpen,
      borderRight: "none",
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .MuiPaper-root': {
        width: drawerWidthOpen,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      borderRight: "none",
      width: drawerWidthClose,
      '& .MuiPaper-root': {
        width: drawerWidthClose,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    listSection: {
      zIndex: 10,
      flexGrow: 1,
      '& .MuiList-padding':{
        paddingTop: 0,
      }
    },
    menuList:{
      '& .MuiListItem-button':{
        paddingLeft : 30,
      },
      "& .selectedMenu":{
        color: "#0E5AA7",
        borderLeft: "3px solid #0E5AA7 !important",
        fontWeight: 600,
        '& .listIcon':{
          stroke: "#0E5AA7",
          strokeWidth: 0.5
        },
        '& .administration':{
          '& path':{
            stroke: "#0E5AA7",
            strokeWidth: 1.5
          },
          '& circle':{
            stroke: "#0E5AA7",
            strokeWidth: 1.5
          }
        },
        '& .MuiListItemText-primary':{
          color: "#0E5AA7"
        },
      },
      '& .active':{
        '& .MuiListItemText-primary':{
          color: "#0E5AA7"
        }
      }
    },
    menuListItem:{
      minHeight: 60,
      borderBottom: "1px solid #fff !important",
      '& .MuiListItemText-primary':{
        fontSize: "14px !important",
        fontWeight: 600,
        color: "#6F6F6F"
      }
    },
    menuListIcon:{
      minWidth: "60px !important"
    },
    nested: {
      paddingLeft: "95px !important",
    },
    listExpand:{
      stroke: "#fff"
    },
    poweredBy: {
      zIndex: 10,
      flexGrow: 1,
      
    },
    logoContainer:{
      paddingLeft: 8
    },
    poweredByLogoSection: {
      position: "absolute",
      bottom: 100,
      left: 12,
      '& .poweredByText':{
        paddingLeft: 4,
        paddingBottom: 8,
        fontSize: 8,
        fontWeight: 600
      },
      '& a': {
        textDecoration: "none"
      },
      '& .bottomCaMSLogo': {
        height: 56,
        maxWidth: "61px !important",
      },
      '& .bottomCaMSLogoCarrefour': {
        height: 50,
        //maxWidth: "85% !important",
      },
      '& .MuiIconButton-root':{
        color: "#B2B2B2",
        marginLeft: 8,
        '&:hover':{
          backgroundColor: '#fff'
        }
      }
    },
  })
);