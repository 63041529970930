import React, { useCallback, useEffect, useRef, useState } from "react";
import { 
  fetchFiltersDropdownValues, 
  fetchExceptionsDeliveryByFilter, 
  fetchExceptionsDeliveryByCount,
  updateExportToExcelPath, 
  resetExceptionsDeliveryError, 
  saveColumnSettings, 
  updateColumnSettings, 
  resetViewCreationStatus, 
  resetViewUpdationStatus,
  resetRepushEnabledFlag,
  repushBulkOrder,
  resetBulkRepushOrder
 } from "./redux/exceptionsDeliverySlice";
import { resetRepushOrder, } from "../orderList/redux/orderListSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createFilterPayload,
  lastMileCountryDataTransform,
  getErrorStatusMsg,
  getErrorStatusFieldReadMore
} from "../mocks/exceptionsDelivery/response.transforms";
import { Link as RouterLink } from 'react-router-dom';
import { AppState } from "../config/redux/reducers";
import { useStyles } from "./ExceptionsDelivery.styles";
import AppConstants from "../constants";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import GridWrapper from "../common/GridWrapper";
import ConsignmentDetails from "../orderList/ConsignmentDetails";
import ExceptionsDeliveryEditDetails from "./ExceptionsDeliveryEditDetails";
import moment from "moment";
import Loader from "../common/Loader";
import CustomStatusBar from "../common/CustomStatusBar";
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { RowData, SortModel, RowParams, SelectionChangeParams } from "../common/GridWrapper/DataTable";

const ExceptionsDelivery = (props:any) => {
  const dispatch = useDispatch();
  const { data, chipsCount, filtersDropdowns, tableLoading, loading, columnList, favoriteViews, error, errorCode, bulkRepushFlag, bulkRepushMsg, viewCreationStatus, viewCreationMsg, viewUpdateStatus, viewUpdateMsg } = useSelector(
    (state: AppState) => state.exceptionsDelivery
  );
  const { repushOrderMsg, repushOrderFlag } = useSelector(
    (state: AppState) => state.orderList
  );
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const [listToShow, setListToShow] = useState([]);
  const [deliveryData, setDeliveryData] = useState({});
  const [openDeliveryDetailsPopup, setOpenDeliveryDetailsPopup] = useState(false);
  const [openConsignmentEditPopup, setOpenConsignmentEditPopup] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState([] as RowData[]);
  const [isColumnSettingsDisabled, setIsColumnSettingsDisabled] = useState(false);
  const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP);
  const [headerData, setHeaderData] = useState([...columnList]);

  const [tableWrapperData, setTableWrapperData] = useState({
    ...AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA, defaultFiltersObj: {
      ...AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA.defaultFiltersObj, deliveryDateRange: {
        startDate: moment().format(AppConstants.DATE_FORMAT),
        endDate: moment().format(AppConstants.DATE_FORMAT)
      }, transactionDateRange: {
        startDate: moment().format(AppConstants.DATE_FORMAT),
        endDate: moment().format(AppConstants.DATE_FORMAT)
      }
    }, HeaderData: headerData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'statusErrors') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeader}>
            <Grid item>{getErrorStatusMsg(params.value)}</Grid>
            <Grid item className="statusMsgMore">{getErrorStatusFieldReadMore(params.value)}</Grid>
          </Grid>
        );
      }
      return newHeader;
    })
  });
  const [deliveryActiveFilters, setDeliveryActiveFilters] = useState({ ...tableWrapperData.defaultFiltersObj });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const componentClasses = useStyles();

  const fetchAllFilters = useCallback(
    () => {
      dispatch(
        fetchFiltersDropdownValues()
      )
    },
    [dispatch]
  );

  const getExceptionsDeliveryByCount = useCallback(
    (filters: any) => {
      let chipFilters = { ...filters, carrierStatus: [] }
      chipFilters.consignmentCode = '';
      chipFilters.orderCode = '';
      chipFilters.trackingId = '';
      dispatch(
        fetchExceptionsDeliveryByCount({filters: chipFilters})
      )
    },
    [dispatch]
  );

  const handleHeaderButtonsDisableState = useCallback(
    () => {
      let headerButtons = tableWrapperData.headerButtons.map((btn: any) => {
        switch (btn.name) {
          case 'repush':
            btn.disabled = selectedDelivery.length && selectedDelivery.length <= 20 ? false : true ;
            break;
          case 'excel':
            btn.disabled = listToShow.length ? (data.totalElements > AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DISABLE_EXPORT_EXCEL ? true : false) : true;
            break;
          case 'colsettings':
            setIsColumnSettingsDisabled(btn.disabled);
            break;
          default:
            break;
        }
        return btn;
      });
      setTableWrapperData({ ...tableWrapperData, headerButtons: headerButtons });
    },
    [tableWrapperData, selectedDelivery, listToShow, data]
  );
  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = tableWrapperData.loadMore;
      loadMoreState.disabled = data.totalPages ? data.pageNumber + 1 >= data.totalPages : true;
      setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
    },
    [tableWrapperData, data]
  );

  const filterCallback = (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
    if (filters) {
      const requestContinuationToken = data.continuationToken;
      let payloadFilters = _.cloneDeep(filters);
      payloadFilters['countryIso'] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      //check chip selection here & set filter data accordingly
      if (!isChipFilterClicked && activeOrdersFilterChip !== AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP) {
        payloadFilters['carrierStatus'] = [activeOrdersFilterChip];
        filters['carrierStatus'] = [activeOrdersFilterChip];
      }else if(filters.carrierStatus.length && filters.carrierStatus[0] !== 'all'){
        payloadFilters['carrierStatus'] = filters.carrierStatus;
      }else if(deliveryActiveFilters.carrierStatus.length){
        payloadFilters['carrierStatus'] = deliveryActiveFilters.carrierStatus;
        filters['carrierStatus'] = deliveryActiveFilters.carrierStatus;
      }

      let requestType = payloadFilters["requestType"];
      //check if filters are resets if yes then set filters data accordingly
      if(filters && filters.resetExceptionFilters){
        delete filters.resetExceptionFilters;
        filters['carrierStatus'] = [AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP];
        payloadFilters['carrierStatus'] = [AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP];
      }
      const payload = createFilterPayload(payloadFilters, data, isLoadMore);
      if(payload.page){
        payload.requestContinuationToken = requestContinuationToken
      }
      getExceptionsDeliveryByCount(payload);
      if(requestType === 'Status Delay'){
        setActiveOrdersFilterChip(AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP);
        filters['carrierStatus'] = [AppConstants.EXCEPTION_DELIVERY_CONSTANTS.DEFAULT_FILTER_CHIP];
      }else{
        setActiveOrdersFilterChip(filters['carrierStatus'][0]);
      }
      setDeliveryActiveFilters(filters);
      dispatch(
        fetchExceptionsDeliveryByFilter({
          filters: payload
        })
      );
    }
  }

  const handleExcelExport = useCallback(
    (filters: any) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters['countryIso'] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createFilterPayload(payloadFilters, data, false, true);
        dispatch(
          updateExportToExcelPath({
            payload: payload
          })
        );
      }
    },
    [dispatch, data, countryCode]
  )

  const handleBulkRepush = useCallback(
    () => {
      if (selectedDelivery && selectedDelivery.length) {
        let payloadData = {
          repushType: 'BULK',
          repushData: []
        }
        let repushDataObj: any = [];
        selectedDelivery.forEach(cons => {
          repushDataObj.push({consignmentCode: cons.consignmentId})
        });
        if(repushDataObj && repushDataObj.length === 1){
          payloadData.repushType = 'SINGLE';
        }
        payloadData.repushData = repushDataObj;
        dispatch(
          repushBulkOrder({
            payload: payloadData
          })
        );
      }
    },
    [dispatch, selectedDelivery]
  )

  const searchFilterHandler = (data: { [key: string]: string }, filters: any, isLoadMore: boolean ) => {
    const searchKeys = Object.keys(data);
    for (const searchKey of searchKeys) {
      data[searchKey] = _.trim(data[searchKey]);
      if(searchKey === 'consignment'){
        filters['consignmentCode'] = data[searchKey];
      }else{
        filters['consignmentCode'] = '';
      }
      if(searchKey === 'tracking'){
        filters['trackingId'] = data[searchKey];
      }else{
        filters['trackingId'] = '';
      }
      if(searchKey === 'order'){
        filters['orderCode'] = data[searchKey];
      }else{
        filters['orderCode'] = '';
      }
      filterCallback(filters, isLoadMore);
      return;
    }
  };

  const handleRowClick = (params: RowParams) => {
    if (data && params.data) {
      const selectedRow = params.data;
      const selectedConsignment = data.content.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
      const consignment = selectedConsignment.length ? { ...selectedConsignment[0], tableRecord: selectedRow } : { tableRecord: selectedRow };
      
      setDeliveryData(consignment);
      setOpenDeliveryDetailsPopup(true);
    }
  }

  const updateSelectedRows = useCallback(
    (params: SelectionChangeParams) => {
      setSelectedDelivery(params.rows);
    },
    []
  );

  const handleHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'repush':
            handleBulkRepush();
            break;
          case 'excel':
            handleExcelExport(btnObj.params);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport, handleBulkRepush]
  );

  const updateConsignmentData = useCallback(
    (params: any) => {
      const payload = createFilterPayload(_.cloneDeep(params));
      if (payload) {
        closeConsignmentEditPopup();
        dispatch(
          resetRepushEnabledFlag(true)
        )
      }
    },
    [dispatch],
  )

  const handleConsignmentUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'submit') {
        updateConsignmentData(params.values);
      } else {
        closeConsignmentEditPopup();
      }
    },
    [updateConsignmentData],
  )

  const openConsignmentUpdatePopup = () => {
    setOpenConsignmentEditPopup(true);
  }

  const closeDeliveryDetailsPopup = () => {
    setOpenDeliveryDetailsPopup(false);
  }

  const closeConsignmentEditPopup = () => {
    setOpenConsignmentEditPopup(false);
  }

  const saveColumnSetting = useCallback(
    (params: any) => {
      dispatch(
        saveColumnSettings({
          itemList: params.itemsList,
          viewName: params.values.viewName
        })
      );
      setHeaderData([...params.itemsList]);
      const headerDataTmp = [...params.itemsList];
      setTableWrapperData({ ...tableWrapperData, HeaderData: headerDataTmp.map((header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === 'statusErrors') {
          newHeader.renderCell = (params: any) => (
            <Grid className={componentClasses.statusHeader}>
              <Grid item>{getErrorStatusMsg(params.value)}</Grid>
              <Grid item className="statusMsgMore">{getErrorStatusFieldReadMore(params.value)}</Grid>
            </Grid>
          );
        }
        return newHeader;
      }) 
    });
    },
    [dispatch, tableWrapperData, componentClasses]
  )

  const updateColumnSetting = useCallback(
    (params: any) => {
      dispatch(
        updateColumnSettings({
          itemList: params.itemList,
          viewName: params.viewName
        })
      );
      setHeaderData([...params.itemList]);
      const headerDataTmp = [...params.itemList];
      setTableWrapperData({ ...tableWrapperData, HeaderData: headerDataTmp.map((header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === 'statusErrors') {
          newHeader.renderCell = (params: any) => (
            <Grid className={componentClasses.statusHeader}>
              <Grid item>{getErrorStatusMsg(params.value)}</Grid>
              <Grid item className="statusMsgMore">{getErrorStatusFieldReadMore(params.value)}</Grid>
            </Grid>
          );
        }
        return newHeader;
      }) 
    });
    },
    [dispatch, tableWrapperData, componentClasses]
  )
  
  const handleColumnSettingsActions = useCallback(
    (params: any) => {
      if (params.type === 'save') {
        saveColumnSetting(params);
      }
    },
    [saveColumnSetting],
  )

  const handleOnChangeFavoriteView = useCallback(
    (params: any) => {
      const activeView = favoriteViews ? _.find(favoriteViews, (n) => { return (n.viewName === params); }): '';
      let payload = {viewName: '', itemList: []};
      payload.itemList = activeView.colList;
      payload.viewName = activeView.viewName;
      updateColumnSetting(payload);
    },
    [favoriteViews, updateColumnSetting],
  )

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSessionExpired = useCallback(
    () => {
      if (errorCode === AppConstants.EXCEPTION_DELIVERY_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.SUCCESS) {
        if (viewCreationStatus) {
          dispatch(
            resetViewCreationStatus()
          )
        }
        if (viewUpdateStatus) {
          dispatch(
            resetViewUpdationStatus()
          )
        }
      }
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR) {
        dispatch(
          resetExceptionsDeliveryError()
        )
      }
    },
    [dispatch, errorCode, viewCreationStatus, viewUpdateStatus],
  )

  const handleSnackbarClose = useCallback(() =>{
      setOpenSnackbar(false);
      handleSessionExpired();
    },
    [handleSessionExpired]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleSessionExpired();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (repushOrderFlag) {
      setOpenDeliveryDetailsPopup(false);
      openSnackbarPopup(repushOrderMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      dispatch(
        resetRepushOrder()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repushOrderFlag])

  useEffect(() => {
    if (bulkRepushFlag) {
      openSnackbarPopup(bulkRepushMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      dispatch(
        resetBulkRepushOrder()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, bulkRepushFlag])

  useEffect(() => {
    if (viewCreationStatus) {
      openSnackbarPopup(viewCreationMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewCreationStatus])

  useEffect(() => {
    if (viewUpdateStatus) {
      openSnackbarPopup(viewUpdateMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewUpdateStatus])

  useEffect(() => {
    setListToShow(lastMileCountryDataTransform(data) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listToShow, selectedDelivery]);

  useEffect(() => {
    let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
      if (filter.fieldName === 'carriers') {
        filter.items = filtersDropdowns.carrierList[countryCode] || [];
      }
      if (filter.fieldName === 'deliveryProposition') {
        filter.items = filtersDropdowns.delPropositionList[countryCode] || [];
      }
      return filter;
    });
    setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    if (tableWrapperData && tableWrapperData.FilterFormData) {
      let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
        if (filter.fieldName === 'carriers') {
          filter.items = filtersDropdowns.carrierList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'deliveryProposition') {
          filter.items = filtersDropdowns.delPropositionList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        return filter;
      });
      setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDropdowns])

  const handleChipFilter = (chip: any, filters: any) => {
    if (filters && chip.code !== activeOrdersFilterChip) {
      filters.carrierStatus = [chip.code];
      filterCallback(filters, false, true);
    }
  }

  const getFilterChips = () => {
    const filterChips = AppConstants.EXCEPTION_DELIVERY_CONSTANTS.FILTER_CHIPS;
    return filterChips.map((chip: any, index: number) => (
      deliveryActiveFilters && deliveryActiveFilters.requestType === 'Forward Exception' ?
      (chip.fieldName !== 'statusDelay' ? 
      <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem ${index === 0 ? 'firstItem' : ''} ${activeOrdersFilterChip === chip.code ? 'active' : ''}`} onClick={() => handleChipFilter(chip, deliveryActiveFilters)}>
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{chipsCount && chipsCount.deliveryChipsCount[chip.fieldName]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      :
      <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem chipDisabled ${index === 0 ? 'firstItem' : ''} ${activeOrdersFilterChip === chip.code ? 'active' : ''}`}>
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{chipsCount && chipsCount.deliveryChipsCount[chip.fieldName]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      )
      :
      deliveryActiveFilters && deliveryActiveFilters.requestType === 'Status Delay' ?
      (chip.fieldName !== 'statusDelay' ? 
      <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem chipDisabled`}>
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{chipsCount && chipsCount.deliveryChipsCount[chip.fieldName]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      :
      <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem active`}>
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{chipsCount && chipsCount.deliveryChipsCount[chip.fieldName]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      )
      :null
    ))
  };

  const query = new URLSearchParams(props.location.search);
  const viewMode = query && query.get('view')

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <CustomStatusBar open={openSnackbar} handleClose={handleSnackbarClose} message={snackbarMessage.current} type={snackbarType.current} />
      <Grid className={componentClasses.container} container>
        <Grid className="planningItem heading" item>
          <Typography>Exceptions</Typography>
        </Grid>
        <Grid className="planningItem content" item>
          <Tabs className="exceptionsRoutes" value='Delivery' aria-label="Navigation Tabs">
            <Tab label="Delivery" value='Delivery' component={RouterLink} to={AppConstants.ROUTES.EXCEPTIONS_DELIVERIES} />
           {!viewMode &&  <Tab label="Returns" value='Returns' component={RouterLink} to={AppConstants.ROUTES.EXCEPTIONS_RETURNS} />} 
          </Tabs>
          <Grid container className={componentClasses.tabPanelContainer}>
            <Grid item className="panelItem chipFilter">
              <Grid container className="chipsContainer">
                {
                  getFilterChips()
                }
              </Grid>
            </Grid>
            <Grid item className="panelItem gridWrapper">
              <GridWrapper
                headerData={tableWrapperData.HeaderData}
                favoriteViews={favoriteViews}
                rowData={listToShow}
                loading={loading ? undefined : tableLoading}
                exceptions={true}
                searchTypes={tableWrapperData.SEARCH_TYPES}
                filterForm={tableWrapperData.FilterFormData}
                defaultFilter={tableWrapperData.defaultFiltersObj}
                headerButtons={tableWrapperData.headerButtons}
                title={tableWrapperData.title}
                sortModel={tableWrapperData.sortModel as SortModel}
                loadMore={{ ...tableWrapperData.loadMore, rowCount: data.totalElements || 0 }}
                onRowClick={handleRowClick}
                onSelectionChange={updateSelectedRows}
                headerBtnClick={handleHeaderButtonClick}
                searchCallback={searchFilterHandler}
                filterGrid={filterCallback}
                fetchAllFilters={fetchAllFilters}
                columnSettingsColList={columnList}
                isColumnSettingsDisabled={isColumnSettingsDisabled}
                onChangeFavoriteView={handleOnChangeFavoriteView}
                saveColumnSettingsActions={handleColumnSettingsActions}
              />
            </Grid>
          </Grid>
          <CustomStatusBar open={openSnackbar} handleClose={handleSnackbarClose} message={snackbarMessage.current} type={snackbarType.current} />
          <ConsignmentDetails pageType={'exceptions'} open={openDeliveryDetailsPopup} consignmentData={deliveryData} handleEditAction={openConsignmentUpdatePopup} closePopup={closeDeliveryDetailsPopup}></ConsignmentDetails>
          <ExceptionsDeliveryEditDetails open={openConsignmentEditPopup} consignment={deliveryData} handleDialogAction={handleConsignmentUpdateActions} closePopup={closeConsignmentEditPopup}></ExceptionsDeliveryEditDetails>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExceptionsDelivery;
