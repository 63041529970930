import React, { useEffect } from 'react';
import './config/theme/globalStyles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Header from './common/Header';
import Routes from './routes';
import { AppState } from './config/redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetUserError } from './Login/redux/loginSlice';
import { resetOrderListError } from './orderList/redux/orderListSlice';
import { resetUsersError, setLoggedInUser } from './Users/redux/usersSlice';
import { resetDashboardListError } from './Dashboard/redux/dashboardSlice';
import AppConstants from './constants/';

interface AppProps {
  location?: any;
  history: any;
}

const App = (props: AppProps) => {

  const { history } = props;
  const { isLoggedIn, error: userLoginError } = useSelector(
    (state: AppState) => state.userLogin
  );
  const { error: orderListError } = useSelector(
    (state: AppState) => state.orderList
  );
  const { error: usersError, isAdmin } = useSelector(
    (state: AppState) => state.users
  );
  const { error: dashboardListError } = useSelector(
    (state: AppState) => state.dashboard
  );
  const { isMenuOpen } = useSelector(
    (state: AppState) => state.common
  );
  const dispatch = useDispatch();

  const hasHeader = isLoggedIn;
  const query = new URLSearchParams(props.location.search);
  const view = query.get('view')

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      if (AppConstants.ROUTES.HOME === location.pathname) {
        dispatch(
          setLoggedInUser()
        )
      }
      if (userLoginError) {
        dispatch(
          resetUserError()
        );
      }
      if (orderListError) {
        dispatch(
          resetOrderListError()
        );
      }
      if (usersError) {
        dispatch(
          resetUsersError()
        );
      }
      if (dashboardListError) {
        dispatch(
          resetDashboardListError()
        );
      }
    });
    return () => {
      unlisten();
    }
  }, [history, dispatch, userLoginError, usersError, orderListError, dashboardListError]);

  return (
    <>
      {view!=='widget' && hasHeader && <Header />}
      <main className={`routesContainer ${hasHeader ? 'hasHeader' : ''} ${isMenuOpen ? 'open' : ''}`}>
        <Routes isLoggedIn={isLoggedIn} isAdmin={isAdmin}/>
      </main>
    </>
  );
};

export default withRouter(App);
