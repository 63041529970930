import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchFiltersDropdownValues, fetchOrderListReturn, fetchOrderListByFilterReturn, updateExportToExcelPathReturn, resetReturnOrder, resetOrderListErrorReturn } from "./redux/returnsListSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createFilterPayload,
  lastMileCountryDataTransform,
  getErrorStatusMsg,
  getErrorStatusFieldReadMore
} from "../mocks/returnsList/response.transforms";
import { AppState } from "../config/redux/reducers";
import { useStyles } from "./ReturnsList.styles";
import AppConstants from "../constants";
import { Grid } from "@material-ui/core";
import GridWrapper from "../common/GridWrapper";
import ConsignmentDetails from "./ConsignmentDetails";
import PrintShippingLabel from "./PrintShippingLabel";
import moment from "moment";
import Loader from "../common/Loader";
import CustomStatusBar from "../common/CustomStatusBar";
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { RowData, SortModel, RowParams, SelectionChangeParams } from "../common/GridWrapper/DataTable";

const ReturnsList = (props:any) => {
  const dispatch = useDispatch();
  const { data, filtersDropdowns, tableLoading, loader, error, errorCode, repushReturnMsg, repushReturnFlag, loading } = useSelector(
    (state: AppState) => state.returnsList
  );
  // const { loading } = useSelector(
  //   (state: AppState) => state.orderList
  // );
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const [listToShow, setListToShow] = useState([]);
  const [consignmentData, setConsignmentData] = useState({});

  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [openPrintShippingLabelPopup, setOpenPrintShippingLabelPopup] = useState(false);
  const [selectedConsignments, setSelectedConsignments] = useState([] as RowData[]);

     const query = new URLSearchParams(props.location.search);
    const carriers = query && query.get('carriers')
    const hubCode = query && query.get('hubCode')
    const arrayOfCarriers = carriers && carriers.split(',')
    const arrayOfHub = hubCode && hubCode.split(',');

  const [tableWrapperData, setTableWrapperData] = useState({
    ...AppConstants.RETURN_CONSTANTS.TABLE_WRAPPER_DATA, defaultFiltersObj: {
      ...AppConstants.RETURN_CONSTANTS.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        startDate: moment().format(AppConstants.DATE_FORMAT),
        endDate: moment().format(AppConstants.DATE_FORMAT)
      },
       hub: arrayOfHub && arrayOfHub.length > 0 ? arrayOfHub : AppConstants.RETURN_CONSTANTS.TABLE_WRAPPER_DATA.defaultFiltersObj.hub
    }, HeaderData: AppConstants.RETURN_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'statusErrors') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeader}>
            <Grid item>{getErrorStatusMsg(params.value)}</Grid>
            <Grid item className="statusMsgMore">{getErrorStatusFieldReadMore(params.value)}</Grid>
          </Grid>
        );
      }
      return newHeader;
    })
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const componentClasses = useStyles();


  const fetchAllFilters = useCallback(
    () => {
      dispatch(
        fetchFiltersDropdownValues()
      )
    },
    [dispatch]
  );

  const fetchOrder = (searchBy: string, value: string, filters: any, isLoadMore: boolean) => {
    if (searchBy && value) {
      let page =  0;
      if(isLoadMore){
        page = data.pageNumber + 1 <= data.totalPages ? data.pageNumber + 1 : 0;
      }
      const requestContinuationToken = data.continuationToken;
      let payload = { page: page } as any;
      if(payload.page){
        payload.requestContinuationToken = requestContinuationToken
      }
      if(searchBy === 'consignment'){
        payload.rmaNumber = value
      }
      if(searchBy === 'tracking'){
        payload.trackingId = value
      }
      if(searchBy === 'order'){
        payload.orderCode = value
      }
      if(searchBy === 'phone'){
        payload.phoneNumber = value
      }
      dispatch(
        fetchOrderListReturn({
          page: page,
          searchBy: searchBy,
          searchValue: value,
          payload: payload
        })
      );
    }
  }

  const handleHeaderButtonsDisableState = useCallback(
    () => {
      let headerButtons = tableWrapperData.headerButtons.map((btn: any) => {
        switch (btn.name) {
          case 'print':
            btn.disabled = !selectedConsignments.length;
            break;
          case 'excel':
            btn.disabled = listToShow.length ? (data.totalElements > AppConstants.CONSIGNMENT_CONSTANTS.DISABLE_EXPORT_EXCEL ? true : false) : true;
            break;
          default:
            break;
        }
        return btn;
      });
      setTableWrapperData({ ...tableWrapperData, headerButtons: headerButtons });
    },
    [tableWrapperData, selectedConsignments, listToShow, data]
  );
  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = tableWrapperData.loadMore;
      loadMoreState.disabled = data.totalPages ? data.pageNumber + 1 >= data.totalPages : true;
      setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
    },
    [tableWrapperData, data]
  );

  const filterCallback = (filters: any, isLoadMore?: boolean) => {
    if (filters) {
      const requestContinuationToken = data.continuationToken;
      let payloadFilters = _.cloneDeep(filters);
      payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      const payload = createFilterPayload(payloadFilters, data, isLoadMore);
      if(payload.page){
        payload.requestContinuationToken = requestContinuationToken
      }
      dispatch(
        fetchOrderListByFilterReturn({
          filters: payload
        })
      );
    }
  }

  const handleExcelExport = useCallback(
    (filters: any) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createFilterPayload(payloadFilters, data, false, true);
        dispatch(
          updateExportToExcelPathReturn({
            payload: payload
          })
        );
      }
    },
    [dispatch, data, countryCode]
  )

  const searchFilterHandler = (data: { [key: string]: string }, filters: any, isLoadMore: boolean ) => {
    const searchKeys = Object.keys(data);
    for (const searchKey of searchKeys) {
      data[searchKey] = _.trim(data[searchKey]);
      if (data[searchKey] && data[searchKey].length >= AppConstants.SEARCH_BOX_LIMIT) {
        fetchOrder(searchKey, data[searchKey], filters, isLoadMore);
        return;
      }else{
        openSnackbarPopup(AppConstants.SEARCH_TEXT_LIMIT, AppConstants.SNACKBAR.TYPES.ERROR);
        return;
      }
    }
  };

  const handleRowClick = (params: RowParams) => {
    if (data && params.data) {
      const selectedRow = params.data;
      const selectedConsignment = data.content.filter((item: any) => item.rmaNumber === selectedRow.returnId);
      const consignment = selectedConsignment.length ? { ...selectedConsignment[0], tableRecord: selectedRow } : { tableRecord: selectedRow };
      setConsignmentData(consignment);
      setOpenConsignmentDetailsPopup(true);
    }
  }

  const updateSelectedRows = useCallback(
    (params: SelectionChangeParams) => {
      setSelectedConsignments(params.rows);
    },
    []
  );

  const handleHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'print':
            setOpenPrintShippingLabelPopup(true);
            break;
          case 'excel':
            handleExcelExport(btnObj.params);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport]
  );

  const closeConsignmentDetailsPopup = () => {
    setOpenConsignmentDetailsPopup(false);
  }

  const closePrintShippingLabelPopup = () => {
    setOpenPrintShippingLabelPopup(false);
  }

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSessionExpired = useCallback(
    () => {
      if (errorCode === AppConstants.RETURN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR) {
        dispatch(
          resetOrderListErrorReturn()
        )
      }
    },
    [dispatch, errorCode],
  )

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
      handleSessionExpired();
    },
    [handleSessionExpired],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleSessionExpired();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (repushReturnFlag) {
      setOpenConsignmentDetailsPopup(false);
      openSnackbarPopup(repushReturnMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      dispatch(
        resetReturnOrder()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repushReturnFlag])

  useEffect(() => {
    setListToShow(lastMileCountryDataTransform(data) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listToShow, selectedConsignments]);

  useEffect(() => {
    let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
      if (filter.fieldName === 'hub') {
        filter.items = filtersDropdowns.hubList[countryCode] || [];
      }
      if (filter.fieldName === 'deliveryStatus') {
        filter.items = filtersDropdowns.statusList[countryCode] || [];
      }
      if (filter.fieldName === 'proposition') {
        filter.items = filtersDropdowns.delPropositionList[countryCode] || [];
      }
      return filter;
    });
    setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    if (tableWrapperData && tableWrapperData.FilterFormData) {
      let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
        if (filter.fieldName === 'hub') {
          filter.items = filtersDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'deliveryStatus') {
          filter.items = filtersDropdowns.statusList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'proposition') {
          filter.items = filtersDropdowns.delPropositionList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        return filter;
      });
      setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDropdowns])

  return (
    <div className={componentClasses.root}>
      {(loading || loader) && <Loader></Loader>}
      {openSnackbar && <CustomStatusBar open={openSnackbar} handleClose={handleSnackbarClose} message={snackbarMessage.current} type={snackbarType.current} />}
      {openPrintShippingLabelPopup && <PrintShippingLabel open={openPrintShippingLabelPopup} selectedRows={selectedConsignments} closePopup={closePrintShippingLabelPopup}></PrintShippingLabel>}
      {openConsignmentDetailsPopup && <ConsignmentDetails open={openConsignmentDetailsPopup} consignment={consignmentData} closePopup={closeConsignmentDetailsPopup}></ConsignmentDetails>}
      <Grid className={componentClasses.pageTitle}>Returns</Grid>
      <GridWrapper
        headerData={tableWrapperData.HeaderData}
        rowData={listToShow}
        loading={loading ? undefined : tableLoading}
        searchTypes={tableWrapperData.SEARCH_TYPES}
        filterForm={tableWrapperData.FilterFormData}
        defaultFilter={tableWrapperData.defaultFiltersObj}
        headerButtons={tableWrapperData.headerButtons}
        title={tableWrapperData.title}
        sortModel={tableWrapperData.sortModel as SortModel}
        loadMore={{ ...tableWrapperData.loadMore, rowCount: data.totalElements || 0 }}
        onRowClick={handleRowClick}
        onSelectionChange={updateSelectedRows}
        headerBtnClick={handleHeaderButtonClick}
        searchCallback={searchFilterHandler}
        filterGrid={filterCallback}
        fetchAllFilters={fetchAllFilters}
      />
    </div>
  );
};

export default ReturnsList;
