import { Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextBox from '../common/TextBox';
import { ReactComponent as carrefourLogo } from './../assets/icons/logo-with-title.svg';
import { useStyles } from './Login.styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';
import { resetUserCreationSuccess, resetUserResetPwd, userLogin, userResetPwd, resetUserError } from './redux/loginSlice';
import Loader from '../common/Loader';
import { useHistory } from 'react-router';
import AppConstants from '../constants';
import { Link } from 'react-router-dom';
import CustomStatusBar from '../common/CustomStatusBar';
export interface LoginProps {
  resetPwd?: boolean;
}

const loginValidationSchema = yup.object({
  username: yup
    .string()
    .required(AppConstants.VALIDATIONS.EMAIL.REQUIRED.msg),
    //.email(AppConstants.VALIDATIONS.EMAIL.VALID_EMAIL.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
});
const resetPwdValidationSchema = yup.object({
  username: yup
    .string()
    .required(AppConstants.VALIDATIONS.EMAIL.REQUIRED.msg),
    //.email(AppConstants.VALIDATIONS.EMAIL.VALID_EMAIL.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref('password')], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg)
});

const UserLogin = (props: LoginProps) => {
  const { resetPwd } = props;
  const dispatch = useDispatch();

  const history = useHistory();

  const { loading, isLoggedIn, userCreationSuccess, resetPwdSuccess, error, errorCode } = useSelector(
    (state: AppState) => state.userLogin
  );

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const loginFormik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      login(values);
    }
  });
  const resetPwdFormik = useFormik({
    initialValues: {
      username: sessionStorage.getItem('username') || '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPwdValidationSchema,
    onSubmit: (values) => {
      resetPassword(values);
    }
  });

  if (!resetPwd) {
    if (resetPwdSuccess) {
      dispatch(
        resetUserResetPwd()
      )
    }
  }

  const login = (payload: any) => {
    if (payload) {
      dispatch(
        userLogin({
          userdata: payload
        })
      );
    }
  }

  const resetPassword = (payload: any) => {
    if (payload) {
      dispatch(
        userResetPwd({
          userdata: payload
        })
      );
    }
  }

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleResetSnackbarOnExit = useCallback(
    () => {
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.SUCCESS && userCreationSuccess) {
        dispatch(
          resetUserCreationSuccess()
        )
      }
    },
    [dispatch, userCreationSuccess],
  )

  const handleResetCustomSnackbarOnExit = useCallback(
    () => {
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR && error) {
        dispatch(
          resetUserError()
        )
      }
    },
    [dispatch, error],
  )

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
      handleResetSnackbarOnExit();
      handleResetCustomSnackbarOnExit();
    },
    [handleResetSnackbarOnExit, handleResetCustomSnackbarOnExit],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleResetSnackbarOnExit();
      handleResetCustomSnackbarOnExit();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push(AppConstants.ROUTES.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (resetPwdSuccess) {
      openSnackbarPopup(AppConstants.LOGIN_CONSTANTS.PWD_CHANGE_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
      history.push(AppConstants.ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPwdSuccess]);

  useEffect(() => {
    if (userCreationSuccess && !resetPwd) {
      openSnackbarPopup(AppConstants.LOGIN_CONSTANTS.USER_CREATION_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreationSuccess])

  useEffect(() => {
    if (resetPwd) {
      resetPwdFormik.resetForm();
    } else {
      loginFormik.resetForm();
    }
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
      if (!resetPwd && errorCode === AppConstants.LOGIN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.PWD_EXP) {
        history.push(AppConstants.ROUTES.RESET_PWD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <Grid className={classes.root} container>
      {loading && <Loader></Loader>}
      <Grid className="mainItem" item>
        <Grid container className={classes.loginContainer} direction="column">
          <Grid className="loginItem" item>
            <Grid container className="logoContainer" direction="column">
              <Grid item>
                <SvgIcon className="logo" viewBox="0 0 285 50" component={carrefourLogo} />
              </Grid>
            </Grid>
          </Grid>
          <CustomStatusBar 
            open={openSnackbar}
            mainClassName={classes.statusMessageMain} 
            iconClassName={classes.statusMessageIcon} 
            textClassName={classes.statusMessageText} 
            closeClassName={classes.statusMessageClose} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage.current} 
            type={snackbarType.current} 
          />
          <form onSubmit={resetPwd ? resetPwdFormik.handleSubmit : loginFormik.handleSubmit}>
            <Grid className="loginItem" item>
              <Grid container className="formContainer" direction="column">
                <Grid className="formItem" item>
                  <Typography className="formTitle">{resetPwd ? 'Reset Password' : 'Login to your account'}</Typography>
                </Grid>
                <Grid className="formItem" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    handleChange={resetPwd ? resetPwdFormik.handleChange : loginFormik.handleChange}
                    value={resetPwd ? resetPwdFormik.values.username : loginFormik.values.username}
                    error={resetPwd ? resetPwdFormik.touched.username && Boolean(resetPwdFormik.errors.username) : loginFormik.touched.username && Boolean(loginFormik.errors.username)}
                    helperText={resetPwd ? resetPwdFormik.touched.username && resetPwdFormik.errors.username : loginFormik.touched.username && loginFormik.errors.username}
                    textBoxId="usernameTextbox"
                    name="username"
                    type="text"
                    placeholderText="Email"
                  ></TextBox>
                </Grid>
                <Grid className="formItem" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    handleChange={resetPwd ? resetPwdFormik.handleChange : loginFormik.handleChange}
                    value={resetPwd ? resetPwdFormik.values.password : loginFormik.values.password}
                    error={resetPwd ? resetPwdFormik.touched.password && Boolean(resetPwdFormik.errors.password) : loginFormik.touched.password && Boolean(loginFormik.errors.password)}
                    helperText={resetPwd ? resetPwdFormik.touched.password && resetPwdFormik.errors.password : loginFormik.touched.password && loginFormik.errors.password}
                    textBoxId="passwordTextbox"
                    type="password"
                    name="password"
                    placeholderText="Password"
                  ></TextBox>
                </Grid>
                {
                  resetPwd &&
                  <Grid className="formItem" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      handleChange={resetPwdFormik.handleChange}
                      value={resetPwdFormik.values.confirmPassword}
                      error={resetPwdFormik.touched.confirmPassword && Boolean(resetPwdFormik.errors.confirmPassword)}
                      helperText={resetPwdFormik.touched.confirmPassword && resetPwdFormik.errors.confirmPassword}
                      textBoxId="confirmPasswordTextbox"
                      type="password"
                      name="confirmPassword"
                      placeholderText="Re-Enter Password"
                    ></TextBox>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid className="loginItem" item>
              <Grid container className="buttonsContainer" direction="column">
                {
                  !resetPwd &&
                  <Grid className="btnItem forgotPwdItem" item>
                    <Link to={AppConstants.ROUTES.RESET_PWD} className="forgotPwdBtn">{AppConstants.BUTTONS.FORGOT_PWD}</Link>
                  </Grid>
                }
                <Grid className="btnItem" item>
                  <Button variant="contained" className="loginBtn primary" type="submit">{resetPwd ? AppConstants.BUTTONS.SUBMIT : AppConstants.BUTTONS.LOGIN}</Button>
                </Grid>
                {
                  !resetPwd &&
                  <Grid className="btnItem newUserItem" item>
                    <Link to={AppConstants.ROUTES.REGISTER} className="forgotPwdBtn">{AppConstants.BUTTONS.REGISTER_USER}</Link>
                  </Grid>
                }
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {/* <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onEnter={handleOpenSnackbarOnEnter} onExited={handleResetSnackbarOnExit} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} /> */}
    </Grid >
  )
}

export default UserLogin;