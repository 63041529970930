import React, { useCallback, useEffect, useRef, useState } from 'react';
import { userLogout } from "../Login/redux/loginSlice";
import { fetchFiltersDropdownValues, fetchDashboardByFilter, fetchDashboardByFilterOnTime, resetDashboardListError } from "./redux/dashboardSlice";
import { AppState } from "../config/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography, TableContainer, TableHead,  Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useStyles } from './dashboard.styles';
import AppConstants from "../constants";
import CustomStatusBar from "../common/CustomStatusBar";
import Filter from '../common/GridWrapper/Filter';
import Loader from "../common/Loader";
import moment from "moment";
import _ from "lodash";
import { 
  createFilterPayload,
  getRankSup,
  getCarrierLogo,
  getCarrierColor
} from "../mocks/dashboard/response.transforms";
const Highcharts = require('highcharts');
require('highcharts-rounded-corners')(Highcharts);

interface DashboardProps {
  loading?: boolean | undefined;
  handleFilterChange?: (fieldName: string, value: any) => void;
  location:any
}

const Dashboard = (props: DashboardProps) => {

  let { handleFilterChange } = props;

  const dispatch = useDispatch();
  const { data, filtersDropdowns, loading, error, errorCode } = useSelector(
    (state: AppState) => state.dashboard
  );
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const query = new URLSearchParams(props.location.search);
  const hubCode = query && query.get('hubCode')
  const arrayOfHub = hubCode && hubCode.split(',');
  const componentClasses = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerStyle, setContainerStyle] = useState({ height: `calc(100vh - 150px)` });
  const [tableContainerStyle, setTableContainerStyle] = useState({ height: `470px` });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [totalConsignments, setTotalConsignments] = useState(0);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);
  const [dashboardWrapperData, setDashboardWrapperData] = useState({
    ...AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA, defaultFiltersObj: {
      ...AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        startDate: moment().subtract(2, 'day').format(AppConstants.DATE_FORMAT),
        endDate: moment().format(AppConstants.DATE_FORMAT)
      },
      hubs: arrayOfHub && arrayOfHub.length > 0 ? arrayOfHub : AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.defaultFiltersObj.hubs
    }
  });
  const [filterForm, setFilterForm] = useState(dashboardWrapperData.FilterFormData);
  const [defaultFilter, setDefaultFilter] = useState(dashboardWrapperData.defaultFiltersObj);
  const [filters, setFilters] = useState(defaultFilter);

  const fetchAllFilters = useCallback(
    () => {
      dispatch(
        fetchFiltersDropdownValues()
      )
    },
    [dispatch]
  );

  const filterCallback = useCallback(
    (filters: any) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createFilterPayload(payloadFilters, data);
        dispatch(
          fetchDashboardByFilter({
            filters: payload
          })
        );
        dispatch(
          fetchDashboardByFilterOnTime({
            filters: payload
          })
        );
      }
    },
    [dispatch, data, countryCode]
  )

  const handleFilters = useCallback(
    () => {
      filterCallback(filters);
    },
    [filters, filterCallback]
  );

  const updateFilter = useCallback(
    (filter: any) => {
      handleFilterChange && handleFilterChange(filter.key, filter.value);
      setFilters({ ...filters, [filter.key]: filter.value });
      setResetFilterFlag(false);
    },
    [filters, handleFilterChange]
  );

  const resetFilters = () => {
    const resetFilters = { ...defaultFilter };
    setFilters(resetFilters);
    setResetFilterFlag(true);
    filterCallback(resetFilters);
  }



  useEffect(() => {
    fetchAllFilters && fetchAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let filterFormData = dashboardWrapperData.FilterFormData.map((filter: any) => {
      if (filter.fieldName === 'carriers') {
        filter.items = filtersDropdowns.carrierList[countryCode] || [];
      }
      if (filter.fieldName === 'hubs') {
        filter.items = filtersDropdowns.hubList[countryCode] || [];
      }
      if (filter.fieldName === 'deliveryProposition') {
        filter.items = filtersDropdowns.delPropositionList[countryCode] || [];
      }
      return filter;
    });
    setDashboardWrapperData({ ...dashboardWrapperData, FilterFormData: filterFormData });
    setFilterForm(filterFormData)
    setDefaultFilter(dashboardWrapperData.defaultFiltersObj);
    filterCallback(dashboardWrapperData.defaultFiltersObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  
  useEffect(() => {
    if (dashboardWrapperData && dashboardWrapperData.FilterFormData) {
      let filterFormData = dashboardWrapperData.FilterFormData.map((filter: any) => {
        if (filter.fieldName === 'carriers') {
          filter.items = filtersDropdowns.carrierList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'hubs') {
          filter.items = filtersDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'deliveryProposition') {
          filter.items = filtersDropdowns.delPropositionList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        return filter;
      });
      setDashboardWrapperData({ ...dashboardWrapperData, FilterFormData: filterFormData });
      setFilterForm(filterFormData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDropdowns])

  useEffect(() => {
    let tableContainerHeight = data.allRows.length ? 128 + data.allRows.length * 62 : 130 + 62;
    setTableContainerStyle({ height: `${tableContainerHeight}px` });
    function handleResize() {
      if (window.innerWidth < 1200) {
        const shrinkHeaderHeight = 30;
        tableContainerHeight = data.allRows.length ? 148 + data.allRows.length * 62 : 130 + 62;
        setContainerStyle({ height: `calc(100vh - ${150 + shrinkHeaderHeight}px)` });
        setTableContainerStyle({ height: `${tableContainerHeight}px` });
      } else {
        tableContainerHeight = data.allRows.length ? 128 + data.allRows.length * 62 : 130 + 62;
        setContainerStyle({ height: `calc(100vh - ${150}px)` });
        setTableContainerStyle({ height: `${tableContainerHeight}px` });
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSessionExpired = useCallback(
    () => {
      if (errorCode === AppConstants.CONSIGNMENT_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR) {
        dispatch(
          resetDashboardListError()
        )
      }
    },
    [dispatch, errorCode],
  )

  const handleSnackbarClose = useCallback(() =>{
      setOpenSnackbar(false);
      handleSessionExpired();
    },
    [handleSessionExpired]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleSessionExpired();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  })
  
  useEffect(() => {
    let categoriesData: any = [];
    let seriesData: any = [];
    data.allRows.forEach((row: any, key: any) => {
      categoriesData.push(row.carrierName);
      let cColor = getCarrierColor(_.toLower(row.carrierName),dashboardWrapperData)
      let colorCode = cColor ? cColor : AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.BAR_DISABLED_COLOR;
      let obj = { y: row.totalConsignments, color: colorCode };
      seriesData.push(obj)
    })
    setTotalConsignments(_.sumBy(data.allRows, 'totalConsignments'));
    AppConstants.DASHBOARD_CONSTANTS.CHARTOPTIONS.xAxis.categories = categoriesData;
    AppConstants.DASHBOARD_CONSTANTS.CHARTOPTIONS.series[0].data = seriesData;
    Highcharts.chart('highchartsContainer', AppConstants.DASHBOARD_CONSTANTS.CHARTOPTIONS);
  }, [data, dashboardWrapperData])

  return (
    <div className={componentClasses.root}>
      <CustomStatusBar open={openSnackbar} handleClose={handleSnackbarClose} message={snackbarMessage.current} type={snackbarType.current} />
      <div className={componentClasses.root}>
        <Grid container className={componentClasses.mainContainer} direction="column">
          <Grid className={componentClasses.filterSection} item>
            <Grid container direction="column">
              <Grid className="filtersContent" container>
                {
                  filterForm.length ? filterForm.map((filter: any, index: any) => (
                    <Filter
                      key={filter.key}
                      filter={filter}
                      updateFilter={updateFilter}
                      defaultValues={defaultFilter}
                      reset={resetFilterFlag}
                      initialValues={{name:`Select ${filter.label}`, value:AppConstants.SELECT_NONE.value}}
                    />))
                    :
                    null
                }
                {
                  <Grid className="buttons" item>
                    <Button variant="contained" className={[componentClasses.button, 'primary'].join(' ')} onClick={handleFilters}>{AppConstants.BUTTONS.FILTER}</Button>
                    <Button variant="contained" className={[componentClasses.button, 'secondary', 'reset-btn'].join(' ')} onClick={resetFilters}>{AppConstants.BUTTONS.RESET}</Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          {loading && <Loader></Loader>}
          <Grid ref={containerRef} className={componentClasses.dashboardContainer} style={containerStyle} item>
            <Grid className={componentClasses.charArea} item>
              <Grid className={componentClasses.charArea1}>
                <Typography className={componentClasses.chartTitle}>Total assigned consignments: {totalConsignments}</Typography>
                <Grid id="highchartsContainer"></Grid>
              </Grid>
              <Grid className={componentClasses.charArea2}>
                {
                  data.top5rows && data.top5rows.length === 1 ?
                    <Grid>
                      <Typography className={componentClasses.paraText}>On-time Performance</Typography>
                      <Typography className={componentClasses.topOneTag}>Consignments delivered on time</Typography>
                      <TableContainer className={`data-table-scrollable-area ${componentClasses.topOneTable}`}>
                        <Table aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                              <img className={componentClasses.carriersLogoLarge} alt={data.top5rows[0].carrierName} src={getCarrierLogo(_.toLower(data.top5rows[0].carrierName), "big")} />
                              <Typography>
                                  {data.top5rows[0].carrierInformation.percentage}%<br />
                                  <span className={componentClasses.smalltext}>
                                    <b>{data.top5rows[0].carrierInformation.delivered}</b> delivered on time / <b>{data.top5rows[0].carrierInformation.assigned}</b> Total
                                  </span>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    :
                    data.top5rows && data.top5rows.length > 1 ?
                    <Grid>
                      <Typography className={componentClasses.paraText}>On-time Performance (Top 5)</Typography>
                      <TableContainer className={`data-table-scrollable-area ${componentClasses.topInfoArea}`}>
                        <Table aria-label="simple table">
                          <TableBody>
                            {data.top5rows && data.top5rows.map((row: any) => (
                              <TableRow key={row.carrierName}>
                                <TableCell align="left"><b>{row.rank}<sup>{getRankSup(row.rank)}</sup></b></TableCell>
                                <TableCell align="left">
                                  <img className={componentClasses.carriersLogo} alt={row.carrierName} src={getCarrierLogo(_.toLower(row.carrierName), "small")} />
                                </TableCell>
                                <TableCell align="right">
                                  <Typography>
                                    <span className={componentClasses.smalltext}>
                                      <b>{row.carrierInformation.delivered}</b> delivered on time / <b>{row.carrierInformation.assigned}</b> Total &nbsp; <b>({row.carrierInformation.percentage}%)</b>
                                    </span>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    :
                    <Grid>
                      <Typography className={componentClasses.paraText}>On-time Performance</Typography>
                      <Grid className={`data-table-scrollable-area ${componentClasses.noRecordContainer}`}>
                        <span className={componentClasses.noRecord}>No record found</span>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </Grid>
            <Grid className={componentClasses.tableSection} style={tableContainerStyle} item>
              <Grid className={componentClasses.tableHeading}>Carrier Performance based on key statuses</Grid>
              <TableContainer className={`data-table-scrollable-area ${componentClasses.tableContainer}`}>
                <Table aria-label="simple table">
                  <TableHead className={componentClasses.tableHead}>
                    <TableRow>
                      <TableCell></TableCell>
                      {
                      AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.carrierStatues && 
                      AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.carrierStatues.map((carrier: any) => (
                        <TableCell key={carrier.label} align="center">{carrier.label}</TableCell>
                      ))
                      } 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.allRows && data.allRows.length ? data.allRows.map((row: any, index: any) => (
                      <TableRow key={row.carrierName}>
                        <TableCell align="center">
                          <Typography 
                            className={componentClasses.carrierName} 
                            style={{backgroundColor:getCarrierColor(_.toLower(row.carrierName),dashboardWrapperData)}}
                            >
                            {_.upperFirst(row.carrierName)}
                            </Typography>
                        </TableCell>
                        {
                        AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.carrierStatues && 
                        AppConstants.DASHBOARD_CONSTANTS.DASHBOARD_WRAPPER_DATA.carrierStatues.map((carrier: any) => (
                          <TableCell key={carrier.label} align="center">{row.statusMap[carrier.label] ? row.statusMap[carrier.label] : 0}</TableCell>
                        ))
                        } 
                      </TableRow>
                    ))
                    :
                      <TableRow >
                        <TableCell align="center" colSpan={11}>
                          <span className={componentClasses.noRecord}>No record found</span>
                        </TableCell>
                      </TableRow>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSessionExpired} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} /> */}
      </div>
    </div>
  )
}

export default Dashboard;